<template>
  <v-dialog @click:outside="close" v-model="dialog" max-width="1000px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
          <span class="">{{ $t("accounts.accounts") }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row>
          <v-col cols="12"
          >
            <data-table-component
                :headers="headers"
                :items="accounts"
                :loading="loading"
                :itemsPerPage2="-1"
                :showFooter="false"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="green darken-1" v-if="$store.getters.isInRole(101)" :loading="loading" dark @click="addRow"
        >{{ $t("add") }}
        </v-btn>
        <v-btn color="primary darken-1" v-if="$store.getters.isInRole(101)" :loading="dialogCopyLoading" dark @click="generateSSO">
          تسجيل دخول
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="red darken-2" @click="close">{{
            $t("close")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <confirm-dialog
        :openDialog="dialogDelete"
        :onClicked="deleteItemConfirm"
        :dialogTitle="$t('delete')"
        :onClose="closeDelete"
    />
    <confirm-dialog
        :openDialog="dialogSave"
        :onClicked="save"
        :dialogTitle="$t('save')"
        :onClose="closeSave"
    />
    <copy-dialog
        :dialog.sync="dialogCopy"
        :label="dialogCopyLabel"
        :value="dialogCopyValue"
    />
  </v-dialog>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import CopyDialog from "../../components/CopyDialog.vue";

export default {
  components: {DataTableComponent, ConfirmDialog, CopyDialog},
  data() {
    return {
      accounts: [],
      dialogCopy: false,
      dialogCopyLabel: 'رابط تسجيل الدخول',
      dialogCopyValue: '',
      dialogCopyLoading: false,
      dialogSave: false,
      dialogDelete: false,
      accountToDelete: null,
      accountToSave: null,
      loading: false,
      valid: true,
      owner: [
        {
          text: this.$t("ownershipTypes.private"),
          value: "private_schools",
        },
        {
          text: this.$t("ownershipTypes.public"),
          value: "public_schools",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
      rulesWithLength: {
        required: (value) =>
            (value != null && value.length < 255) ||
            this.$t("ThisFieldIsRequired"),
      },
    };
  },
  computed: {
    headers() {
      var list = [
        {text: this.$t("accounts.name"), value: "name", type: "textfield"},
        {text: this.$t("accounts.email"), value: "email", type: "email"},
        {
          text: this.$t("accounts.password"),
          value: "password",
          type: "textfield",
          mark: function (item) {
            return item.password_updated_at !== null;
          }
        },
        {
          text: this.$t("accounts.type"),
          value: "type",
          type: "select",
          items: [
            {
              text: this.$t("accounts.super"),
              value: 1,
            },
            {
              text: this.$t("accounts.support"),
              value: 2,
            },
          ],
          readonly: true,
        },
      ];
      var actions = {
        text: "",
        value: "actions",
        templates: [],
      };
      if (this.$store.getters.isInRole(7)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-content-save-outline",
          text: this.$t("save"),
          click: this.saveItem,
          color: "green darken-2",
        });
      }
      if (this.$store.getters.isInRole(9)) {
        actions.templates.push({
          type: "btn",
          icon: "mdi-delete",
          text: this.$t("delete"),
          click: this.deleteItem,
          color: "red darken-2",
        });
      }
      list.push(actions);
      return list;
    },
  },
  props: ["onClose", "dialog", "schoolId"],
  watch: {
    resetValidation() {
      this.resetForm();
    },
    dialog(val) {
      if (val) this.refreshTable();
    },
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    close() {
      this.accounts = [];
      this.$emit("closeAccounts");
    },
    save() {

      this.loading = true;
      if (
          this.accountToSave.name == null ||
          this.accountToSave.name == undefined ||
          this.accountToSave.name == "" ||
          this.accountToSave.email == null ||
          this.accountToSave.email == undefined ||
          this.accountToSave.email == "" ||
          this.accountToSave.password == null ||
          this.accountToSave.password == undefined ||
          this.accountToSave.password == ""
      ) {
        this.$toast.warning(this.$t("allFieldsRequired"));
        this.loading = false;
        return;
      }
      var item = this.accountToSave;
      if (item.id != undefined) {
        this.$axios
            .post("UpdateSchoolAccount/" + item.id, item)
            .then(() => {
            })
            .finally(() => {
              this.loading = false;
              this.dialogSave = false;
              this.refreshTable();
            });
      } else {
        this.$axios
            .post("AddSchoolAccount", item)
            .then(() => {
            })
            .finally(() => {
              this.loading = false;
              this.dialogSave = false;
              this.refreshTable();
            });
      }
    },
    async generateSSO() {
      try {
        this.dialogCopyLoading = true;
        const response = await this.$axios.post("generateSSO/" + this.schoolId, {noToast: true})
        this.dialogCopyValue = response.data;
        this.dialogCopy = true;
      } finally {
        this.dialogCopyLoading = false;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeSave() {
      this.dialogSave = false;
    },
    deleteItem(item) {
      if (item.id != undefined) {
        this.accountToDelete = item;
        this.dialogDelete = true;
      } else {
        this.accounts.splice(this.accounts.indexOf(item), 1);
      }
    },
    saveItem(item) {
      this.accountToSave = item;
      this.dialogSave = true;
    },
    deleteItemConfirm() {
      if (this.accountToDelete.type == 1) {
        this.dialogDelete = false;
        return;
      }
      this.$axios
          .delete("DeleteSchoolAccount/" + this.accountToDelete.id)
          .then(() => {})
          .finally(() => {
            this.closeDelete();
            this.refreshTable();
          });
    },
    addRow() {
      if (this.$store.getters.isInRole(101))
        this.accounts.push({type: 2, school_id: this.schoolId});
    },
    refreshTable() {
      this.$axios
          .get("GetSchoolAccount?school_id=" + this.schoolId, {noToast: true, failureToast: true})
          .then((response) => {
            this.accounts = response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
  },
};
</script>
