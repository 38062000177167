<template>
  <v-pagination v-model="currentPage" :total-visible="5" :length="lastPage">
  </v-pagination>
</template>

<script>
export default {
  props: ["current_page"],
  computed: {
    currentPage: {
      get() {
        return this.current_page;
      },
      set(value) {
        this.$emit("update:current_page", value);
      },
    },
    lastPage: {
      get() {
        return this.$store.state.schools.last_page;
      },
    },
  },
};
</script>

<style></style>
