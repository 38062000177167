<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schools.creation_log")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea
                  outlined
                  hide-details
                  rows="10"
                  readonly
                  persistent-hint
                  v-model="creation_log"
                  :label="$t('schools.creation_log')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "creation_log",
  ],
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
  },
};
</script>
