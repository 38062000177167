<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schools.edit_finance")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="editedItem">
          <v-row>
            <v-col cols="12" md="12" v-if="this.$store.getters.isInRole(102)">
              <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="editedItem.paid_drivers_count"
                  :label="$t('schools.paid_drivers_count')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" v-if="this.$store.getters.isInRole(102)">
              <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="editedItem.paid_students_count"
                  :label="$t('schools.paid_students_count')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" v-if="this.$store.getters.isInRole(102)">
              <v-text-field
                  outlined
                  hide-details
                  persistent-hint
                  type="number"
                  v-model="editedItem.paid_teachers_count"
                  :label="$t('schools.paid_teachers_count')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="save()" :disabled="submitLoading" :loading="submitLoading">
          {{ $t("save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "refreshTable",
    "editedItem",
    "year_id",
  ],
  data() {
    return {
      submitLoading: false,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    save() {
      this.submitLoading = true;
      this.$axios
          .post(`UpdateSchoolFinance/${this.editedItem.id}`, {
            paid_drivers_count: this.editedItem.paid_drivers_count,
            paid_students_count: this.editedItem.paid_students_count,
            paid_teachers_count: this.editedItem.paid_teachers_count,
            year_id: this.year_id,
          }, {noToast: true})
          .then(() => {
          })
          .finally(() => {
            this.close();
            this.refreshTable();
            this.submitLoading = false;
          })
    },
  },
};
</script>
