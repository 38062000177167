<template>
  <v-dialog @click:outside="close" v-model="dialog" max-width="800px">
    <div>
      <v-btn class="sticky" color="green darken-1" block dark @click="print">
        <v-icon> mdi-printer-outline </v-icon>
        {{ $t("print") }}
      </v-btn>
      <v-card class="px-4" style="text-align: right" id="print">
        <!--Schools Tables-->
        <svg v-for="school in schoolsList" :key="school.id" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 2480 3508">
          <defs>
            <clipPath id="clip-Web_1920_1">
              <rect width="2480" height="3508"></rect>
            </clipPath>
          </defs>
          <g id="Web_1920_1" data-name="Web 1920 – 1" clip-path="url(#clip-Web_1920_1)">
            <rect width="2480" height="3508" fill="#fff"></rect>
            <image id="guide" width="2480" height="3508" xlink:href="../../assets/guide3.png"></image>
            <text id="school_name" dominant-baseline="middle" text-anchor="middle" transform="translate(1100 1600)" fill="#393939" font-size="60" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-181.318" y="0">{{ school.name }}</tspan></text>
            <text id="domain" dominant-baseline="middle" text-anchor="middle" transform="translate(1100 1740)" fill="#393939" font-size="60" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-181.318" y="0">{{ school.domain }}.school.iq</tspan></text>
            <text id="email" dominant-baseline="middle" text-anchor="middle" transform="translate(1100 1880)" fill="#393939" font-size="60" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-181.318" y="0">{{ school.email }}</tspan></text>
            <text id="password" dominant-baseline="middle" text-anchor="middle" transform="translate(1100 2020)" fill="#393939" font-size="60" font-family="SegoeUI-Bold, Segoe UI" font-weight="700"><tspan x="-181.318" y="0">{{ school.password }}</tspan></text>
          </g>
        </svg>
        <!--Schools Names-->
        <v-row v-if="!schoolsList || schoolsList.length === 0">
          <v-col cols="12">
            <v-alert color="warning">لا يوجد بيانات للطباعة</v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div
                v-for="school in schoolsList"
                :key="school.id + 100"
                style="padding: 10px"
            >
              <p
                  style="
                  direction: rtl;
                  text-align: center;
                  font-size: 24px;
                  margin-bottom: 20px;
                "
              >
                {{ school.name | truncate(50) }} /
                {{
                  school.directorate.governorate != undefined
                      ? school.directorate.governorate.name
                      : null
                }}
              </p>
              <hr />
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: ["onClose", "dialog", "schools"],
  data() {
    return {
      schoolsList: null,
      dialogSave: false,
      dialogDelete: false,
      accountToDelete: null,
      accountToSave: null,
      loading: false,
      valid: true,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.schools.forEach(function (element) {
          var superAccount = element.accounts.filter(
              (account) => account.type == 1
          );

          if (superAccount.length > 0) {
            element.email = superAccount[0].email;
            element.password = superAccount[0].password;
          }
        });
        this.schoolsList = this.schools.filter(
            (school) =>
                school.email != undefined &&
                school.email != "" &&
                school.email != null
        );
      }
    },
  },
  methods: {
    close() {
      this.account = null;
      this.$emit("closePrint");
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("print").innerHTML;

      // Open the print window
      const WinPrint = window.open(
          "",
          "",
          "toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                              <html>
                                <head>
                                </head>
                                <body>
                                  ${prtHtml}
                                </body>
                              </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.onload = function() {
        this.print();
        setTimeout(() => this.close(), 100)
      };
    },
  },
};
</script>
<style scoped>
.text-position {
  z-index: 900; position: absolute; margin-top: 488px; margin-right: 300px
}
.text-2 {
margin-top: 22px;
}
.text-3 {
  margin-top: 20px;
}
.text-4 {
  margin-top: 18px;
}


</style>
