<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                title
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                  class="text-left"
                  outlined
                  hide-details
                  rows="10"
                  readonly
                  persistent-hint
                  v-model="value"
                  :label="label"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="info" text @click="copy()">
          {{ $t("Copy") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.text-left {
  input {
    text-align: left;
    direction: ltr;
  }
}
</style>
<script>

export default {
  props: [
    "dialog",
    "title",
    "value",
    "label",
  ],
  data() {
    return {
    };
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    async copy() {
      await navigator.clipboard.writeText(this.value);
    },
    close() {
      this.$emit('update:dialog', false)
    },
  },
};
</script>
