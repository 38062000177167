<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schools.edit_finance")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container >
          <v-col cols="12" md="12" v-if="this.$store.getters.isInRole(102)">
            <v-select
              outlined
              dense
              clearable
              hide-details
              v-model="agency_id"
              :items="agencies.filter(i=>i.id>=0)"
              item-text="title"
              item-value="id"
              label="الوكالة"
              />
          </v-col>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="save()" :disabled="submitLoading" :loading="submitLoading">
          {{ $t("save") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "refreshTable",
    "schools",
    "agencies",
    "year_id",
  ],
  data() {
    return {
      submitLoading: false,
      agency_id: false,
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
      },
    };
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    save() {
      this.submitLoading = true;
      this.$axios
          .post(`UpdateSchoolsAgency/`, {
            agency_id: this.agency_id,
            schools_ids: this.schools.map(i=>i.id),
          })
          .then(() => {
          })
          .finally(() => {
            this.close();
            this.refreshTable();
            this.submitLoading = false;
          })
    },
  },
};
</script>
