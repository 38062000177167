<template>
  <v-dialog v-model="dialog" persistent max-width="1200px">
    <v-form ref="form" v-model="valid" >
      <v-card>
        <v-toolbar color="primary darken-1" dark>
          <v-card-title>
            <span class="">{{
              $t("config.config")
            }}</span>
          </v-card-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                    outlined
                    hide-details
                    dense
                    persistent-hint
                    v-model="form.app_status"
                    :items="app_status"
                    item-text="text"
                    item-value="value"
                    :label="$t('config.app_status')"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6"
                     v-if="form.app_status === 4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  persistent-hint
                  v-model="form.status_message"
                  :label="$t('config.status_message')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  persistent-hint
                  type="date"
                  v-model="form.state_date"
                  :label="$t('config.state_date')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                    outlined
                    hide-details
                    persistent-hint
                    v-model="form.single_device"
                    :label="$t('config.single_device')"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="12">
                <v-data-table
                    :items="menu"
                    :headers="headers"
                    :hide-default-footer="true"
                >
                  <template v-slot:item.app_menu_item_id="{ item }">
                    <v-select
                        outlined
                        hide-details
                        dense
                        persistent-hint
                        v-model="item.app_menu_item_id"
                        :items="menu_items"
                        item-text="name"
                        item-value="id"
                        :style="{'max-width': item.status === 0 ? '200px' : 'unset'}"
                        :label="$t('config.app_menu_item_id')"></v-select>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <v-text-field
                        v-model="item.name"
                        outlined
                        dense
                        :label="$t('config.name')"
                        hide-details
                    ></v-text-field>
                  </template>
                  <template v-slot:item.status="{ item }">
                    <v-select
                        outlined
                        hide-details
                        dense
                        persistent-hint
                        v-model="item.status"
                        :items="status"
                        item-text="text"
                        item-value="value"
                        style="max-width: 125px"
                        :label="$t('config.status')"></v-select>
                  </template>
                  <template v-slot:item.message="{ item }">
                    <v-text-field
                        v-if="item.status === 0"
                        v-model="item.message"
                        outlined
                        dense
                        style="max-width: unset"
                        :label="$t('config.message')"
                        hide-details
                    ></v-text-field>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:item.delete="{ index }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        icon
                        x-small
                        color="error"
                        v-on="on"
                        v-bind="attrs"
                        @click="removeRow(index)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("delete") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1"  dark @click="addRow"
          >{{ $t("config.add") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              class="white--text"
              @click="save"
              :loading="submitLoading"
              :disabled="!valid || submitLoading"
              :min-width="100"
          >
            <v-icon>mdi-content-save-outline</v-icon>
            {{ $t("save") }}
          </v-btn>
          <v-btn color="red" text @click="onClose">
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "onSave",
    "onClose",
    "dialog",
    "schoolId",
    "resetValidation",
  ],
  data() {
    return {
      valid: true,
      submitLoading: false,
      app_status: [
        {
          text: 'فعال',
          value: 1,
        },
        {
          text: 'لا يمكن اضافة معلومات جديدة',
          value: 2,
        },
        {
          text: 'للاطلاع فقط',
          value: 3,
        },
        {
          text: 'معطل',
          value: 4,
        },
      ],
      status: [
        {
          text: 'معطلة',
          value: 0,
        },
        {
          text: 'فعاله',
          value: 1,
        },
      ],
      menu_items: [],
      menu: [],
      form: {
        app_status: null,
        status_message: null,
        state_date: null,
        single_device: null,
      },
      rules: {
        required: (value) => !!value || this.$t("ThisFieldIsRequired"),
        length: (value) => value.length < 255 || this.$t("ThisFieldIsRequired"),
      },
      rulesWithLength: {
        required: (value) =>
          (value != null && value.length < 255) ||
          this.$t("ThisFieldIsRequired"),
      },
    };
  },

  watch: {
    resetValidation() {
      this.resetForm();
    },
    schoolId: {
      immediate: true,
      async handler(val) {
        if (!val) return;
        this.$axios
            .get(
                "config/" + val, {noToast: true}
            )
            .then((response) => {
              this.form = {
                app_status: response.data.config ? response.data.config.app_status : null,
                status_message: response.data.config ? response.data.config.status_message : null,
                state_date: response.data.config && response.data.config.state_date ? response.data.config.state_date.split(' ')[0] : null,
                single_device: response.data.config ? response.data.config.single_device : null,
              };
              this.menu = response.data.menu;
              this.menu_items = response.data.menu_items;
            });
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('config.app_menu_item_id'),
          align: 'center',
          sortable: false,
          value: 'app_menu_item_id',
          sort: true,
        },
        {
          text: this.$t('config.name'),
          align: 'center',
          sortable: false,
          value: 'name',
          sort: true,
        },
        {
          text: this.$t('config.status'),
          align: 'center',
          sortable: false,
          value: 'status',
          sort: true,
        },
        {
          text: this.$t('config.message'),
          align: 'center',
          sortable: false,
          value: 'message',
          sort: true,
        },
        {
          text: this.$t('config.delete'),
          align: 'center',
          sortable: false,
          value: 'delete',
          sort: true,
        },
      ];
    },
  },
  methods: {
    addRow() {
      this.menu.push(
          {
            app_menu_item_id: null,
            name: null,
            status: null,
            message: null
          })
    },
    removeRow(index) {
      this.menu.splice(index, 1)
    },
    resetForm() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    save() {
      var val = this.$refs.form.validate();
      if (val) {
        this.submitLoading = true;
        let payload = {
          ...this.form,
          menu: this.menu,
        };
        this.$axios
            .post(
                "config/" + this.schoolId, payload
            )
            .then((response) => {
              if (response.data.status == "Successful") {
                this.onClose();
                this.$emit("refreshTable");
              }
            })
            .finally(() => {
              this.submitLoading = false;
            })
      }
    },
  },
};
</script>
