<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-toolbar color="primary darken-1" dark>
        <v-card-title>
            <span class="">{{
                $t("schools.view")
              }}</span>
        </v-card-title>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="school && school.id > 0">
          <v-row>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.school_name') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.name }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.governorate_id') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.directorate.governorate.name }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.directorate_id') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.directorate.name }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.owner') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ $t('schools.' + school.owner) }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.manager_name') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.manager_name }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.manager_phone') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.manager_phone }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.types') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <v-chip small class="mx-2" v-for="type in school.types"
                        :key="school.id + '-' + type.id">{{ $t("schoolTypes." + type.school_type) }}</v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.status') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <v-chip v-if="school.activation_status === 0" color="error" small label>
                    {{ $t('schools.not_active') }}
                  </v-chip>
                  <v-chip v-else-if="school.activation_status === 1" color="success" small label>
                    {{ $t('schools.active') }}
                  </v-chip>
                  <v-chip v-else-if="school.activation_status === null" color="warning" small label>
                    {{ $t('schools.not_yet_active') }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="school.locality" cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.locality') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.locality || '-' }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="school.district" cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.district') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.district || '-' }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="school.village" cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.village') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.village || '-' }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="school.closest_point" cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.closest_point') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.closest_point || '-' }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="12">
              <v-row>
                <v-col cols="4" md="4">
                  <p>{{ $t('schools.created_at') }}</p>
                </v-col>
                <v-col cols="8" md="8">
                  <p class="font-weight-bold">{{ school.created_at | format_date_time }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="close()">
          {{ $t("cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "dialog",
    "school",
  ],
  data() {
    return {};
  },
  computed: {},
  methods: {
    close() {
      this.$emit('update:dialog', false)
    }
  },
};
</script>
